import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { useContext, useEffect, useState } from 'react';
import LandingPage from './Components/Front-end/Home Page/LandingPage';
import AuthContext from './Components/Common/auth-context'
import AdminPanel from './Components/Admin Panel/Menu/AdminPanel';
import LoginPage from './Components/Admin Panel/Login Form/LoginPage';
import ProductPage from './Components/Front-end/Product List Page/ProductPage';
import ScrollToTop from './Components/Common/ScrollToTop';
import ProductDetailsPage from './Components/Front-end/Product Details Page/ProductDetailsPage';
import CheckOut from './Components/Front-end/CheckOut Page/CheckOut';
import CheckOutPage from './Components/Front-end/CheckOut Page/ChecOutPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const image = new Image();
    image.src = './assets/Home.png';
    image.onload = () => {
      authCtx.setisLoaded(true);
    };
  }, []);
  return (
    <>
      {/* <button onClick={()=>console.log()}>test</button>
      <button onClick={()=>console.log(authCtx.onLogin())}>login</button>
<button onClick={()=>console.log(authCtx.onLogout())}>logout</button>*/}

      <Routes>
        {/* <Route path='/' element={authCtx.isLoaded ? <LandingPage/> : <Loading/>} /> */}
        <Route path='/' element={<ScrollToTop><LandingPage/></ScrollToTop>} />
        <Route path='/products-page/:Collection' element={<ScrollToTop><ProductPage/></ScrollToTop>} />
        <Route path='/products-details/:id' element={<ScrollToTop><ProductDetailsPage/></ScrollToTop>} />
        <Route path='/check-out' element={<ScrollToTop><CheckOutPage/></ScrollToTop>} />
        <Route path='/admin' element={!authCtx.user ? <LoginPage /> : <AdminPanel/>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{color:'red'}}
        />
    </>
  );
}

export default App;
