import React, { useState } from 'react';
import classes from "./SearchBox.module.css";
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IoSearchOutline } from "react-icons/io5";



function SearchBox() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <div style={{
        width: "60%",
        display:'flex',
        flexDirection:'row',
        borderRadius:'.4em',
        background:'#fff',
        border:'1px solid #000',
        // padding:'1% 0'
    }} 
    className={classes.custom_select}
    >
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        className={classes.input}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            width:"80%" ,
            padding:'.7em 1em',
            height:'100% !important',
            background:'transparent',
        }}
      />
      <button className={classes.btn}><IoSearchOutline style={{width:'1.7em', height:'1.7em',margin:'-0.5em'}}/></button>
    </div>
    <div style={{
        width: "100%",
        borderRadius:'20px',
        background:'#fff',
        border:'1px solid #f3f3f3',
        // padding:'1% 0'
    }} 
    className={classes.custom_select_mob}
    >
      {/* <SearchIcon style={{color:'#ACACAC', margin:'auto 0.2em', width:'1em'}}/> */}
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        className={classes.input}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            borderRadius:'20px',
            padding:'0em 0em 0 1em',
            background:'var(--secondary-color)',
            margin:'auto 0',
        }}
      />
      <button className={classes.btn}><SearchIcon style={{width:'1.2em', height:'1.2em',margin:'-0.3em'}}/></button>
      <div>
    </div>
    </div>
    </>
  );
}
 export default SearchBox