import React, { useContext } from "react";
import classes from "./NewArrivals.module.css";
import img1 from "../../../../assets/segarImg.png";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useDispatch } from "react-redux";
import AuthContext from "../../../Common/auth-context";
import { addSelectedBook, addTocart } from "../../../Common/redux/productSlice";
import { useNavigate } from "react-router";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";


// const dataa = [
//   {
//     id: 1,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 2,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 3,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 4,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 5,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 6,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 7,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },
//   {
//     id: 8,
//     name: "Cigar Padron",
//     image: img1,
//     description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud in quia quat. Duis aute irure…",
//     price: "20.00",
//   },

// ];

const NewArrivals = () => {

  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataa = authCtx.allCategories?.filter(item => item.newarrival);
  return (
    <>
      <div className={classes.bigContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h1 style={{margin:'.2em 0'}} onClick={()=>console.log(dataa)}>New Arrivals</h1>
            <div className={classes.border} />
          </div>
        </div>
        <div className={classes.cardsContainer}>
            {dataa?.slice(0,8).map((props) => {
              return (
                  <div className={classes.card_container} onClick={(event)=>{event.stopPropagation();dispatch(addSelectedBook({...props, quantity: 1}));navigate(`/products-details/${props.id}`)}}>
                    <div className={classes.card_img}>
                      <img
                          src={props.images[0]}
                          alt=""
                          className={classes.img}
                        />
                      <div className={classes.addContainer} onClick={(event)=>event.stopPropagation()&dispatch(addTocart({...props, quantity: 1}))}>
                      <LocalMallOutlinedIcon style={{marginBottom:'-.2em'}}/> Add To Bag
                      </div>
                    </div>
                    <div className={classes.card_content}>
                      <h3 style={{width:'100%',fontWeight:'800',fontSize:'calc(0.8rem + 0.3vw)'}}>{props.name}</h3>
                      <p style={{width:'100%',fontWeight:'700',margin:'0'}}>$ {props.price}</p>
                    </div>
                  </div>
              );
            })}
        </div>
        <div className={classes.cardsContainerMob}>
          <Swiper
            spaceBetween={20}
            effect={"fade"}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{
              padding: "0 1em",
              width:"100%"
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              651: {
                slidesPerView: 3,
              },
              951: {
                slidesPerView: 4,
              },
            }}
          >
            {dataa?.map((props) => {
              return (
                <SwiperSlide key={props.id} className={classes.swiperslide}>
                  <div className={classes.card_container} onClick={(event)=>{event.stopPropagation();dispatch(addSelectedBook({...props, quantity: 1}));navigate(`/products-details/${props.id}`)}}>
                    <div className={classes.card_img}>
                      <img
                          src={props.images[0]}
                          alt=""
                          className={classes.img}
                        />
                      <div className={classes.addContainer}onClick={(event)=>event.stopPropagation()&dispatch(addTocart({...props, quantity: 1}))}>
                      <LocalMallOutlinedIcon style={{marginBottom:'-.2em',fontSize:'1.3em'}}/> Add To Bag
                      </div>
                    </div>
                    <div className={classes.card_content}>
                      <h3 style={{width:'100%',fontWeight:'800',fontSize:'calc(0.8rem + 0.3vw)'}}>{props.name}</h3>
                      <p style={{width:'100%',fontWeight:'700',margin:'0'}}>$ {props.price}</p>
                    </div>
                  </div>
                  
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default NewArrivals;
