import classes from './Sidebar.module.css'
import { Link } from "react-router-dom";
import Logo from '../../../assets/newww.svg'
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import Hookah from '../../../assets/Hookah.svg';
import { GiSmokingPipe } from "react-icons/gi";
import { GiCigar } from "react-icons/gi";
import { GiLighter } from "react-icons/gi";
import { GiSmokeBomb } from "react-icons/gi";
import { BsFillBasket3Fill } from "react-icons/bs";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";



export default function Sidebar({toggle, isOpen}) {

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggle}
      onKeyDown={toggle}
      className={classes.container}
    >
      <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText>
              <Link  to='home' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
                <img src={Logo} alt='Logo' className={classes.logo}/>
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </List>
          <Divider color='white'/>
          <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ShoppingCartOutlinedIcon  className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/All_Products' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
                  Shop All
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <img alt='Hookah' src={Hookah} style={{width:'1em'}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Hookah' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Hookah
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GiSmokingPipe style={{fontSize:'2em'}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Pipe' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Pipe
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <BsFillBasket3Fill style={{fontSize:"1.2em"}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Ashtray' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Ashtray
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GiLighter style={{fontSize:'1.5em'}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Lighter' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Lighter
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
            <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GiSmokeBomb style={{fontSize:'2em'}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Humidifier' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Humidifier
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GiCigar style={{fontSize:'1.7em'}} className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Cigar' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Cigar
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <StarBorderPurple500Icon className={classes.text}/>
              </ListItemIcon>
              <ListItemText className={classes.text}>
              <Link  to='/products-page/Accessories' smooth={true}  duration={500} onClick={toggle} style={{ textDecoration: "none",color:'#000',fontWeight:"600" }}>
              Accessories
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={toggle}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}