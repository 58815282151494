import classes from "./Shop.module.css";
import React, { useEffect, useRef, useState } from "react";
import Shop1 from '../../../../assets/Shop1.png'
import Shop2 from '../../../../assets/Shop2.png'
import Shop3 from '../../../../assets/Shop3.png'
import { useNavigate } from "react-router";


const ShopSection = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.featuredContainer}>
      <div className={classes.content}>
        <div className={classes.imageCont}>
            <img src={Shop1} alt="" />
            <p className={classes.shopBtn} onClick={()=>navigate('/products-page/Humidifier')}>Shop Now</p>
        </div>
        <div className={classes.imageCont}>
            <img src={Shop2} alt="" />
            <p className={classes.shopBtn} style={{color:'#000',fontWeight:'900'}} onClick={()=>navigate('/products-page/Hookah')}>Shop Now</p>
        </div>
        <div className={classes.imageCont}>
            <img src={Shop3} alt="" />
            <p className={classes.shopBtn} onClick={()=>navigate('/products-page/Accessories')}>Shop Now</p>
        </div>
      </div>
    </div>
  );
};

export default ShopSection;
