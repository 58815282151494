import classes from "./Featured.module.css";
import React, { useEffect, useRef, useState } from "react";
import FeaturedImg from '../../../../assets/FeaturedImg.png'
import { useNavigate } from "react-router-dom";


const FeaturedSection = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.featuredContainer}>
      <div className={classes.content}>
        <div className={classes.text} >
          <h1>Featured</h1>
          <p>
          A wave of exciting new arrivals has just landed in our humidor! We've hand-selected a stunning collection of cigars, each crafted with the discerning connoisseur in mind.
          <br/>Unveil your next smoking ritual and discover a new favorite amongst these exceptional additions.          </p>
          <button onClick={()=>navigate(`/products-page/Cigar`)}>Shop Now</button>
        </div>
        <div className={classes.imageCont}>
          <div className={classes.card_img}>
            <img src={FeaturedImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedSection;
