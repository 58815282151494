import React, { useRef, useState } from "react";
import classes from "./LighterModal.module.css";

import addImage from "../../../../../assets/addImage.png";
import TextField from "@mui/material/TextField";
import Card from "../../../../Common/Card/Card";
import AuthContext from "../../../../Common/auth-context";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const AddLighter = (props) => {
 
  const authCtx = React.useContext(AuthContext);

  const fileInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const [id, setId] = useState(authCtx.selectedItem.id);
  const [images, setImages] = useState(authCtx.selectedItem.images || [null, null, null, null]);
  const [viewImages, setViewImages] = useState(authCtx.selectedItem.images || [null, null, null, null]);
  const [name, setName] = useState(authCtx.selectedItem.name || "");
  const [description, setDescription] = useState(authCtx.selectedItem.description || "");
  const [price, setPrice] = useState(authCtx.selectedItem.price || "");
  const [newarrival, setNewarrival] = useState(authCtx.selectedItem.newarrival || false);
  const [mostpopular, setMostpopular] = useState(authCtx.selectedItem.mostpopular || false);
  const [size, setSize] = useState(authCtx.selectedItem.size || "");
  const [color, setColor] = useState(authCtx.selectedItem.color || "");

  const handleImageChange = (index, event) => {
    const selectedImage = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedImage);
    const updatedImages = [...images];
    updatedImages[index] = selectedImage;
    const updatedviewImages = [...viewImages];
    updatedviewImages[index] = imageUrl;
    setImages(updatedImages);
    setViewImages(updatedviewImages);
  };

  const addHookahBtn = () => {
    if (!authCtx.editmood) {
      const data = {
        key: authCtx.lighter.length + 1,
        name: name,
        description: description,
        price: price,
        newarrival: newarrival,
        mostpopular: mostpopular,
        color: color,
        size: size,
        type: "lighter",
      };
      authCtx.addLighter(data, images);
      authCtx.setOpenLighterModal(false);
    } else {
      const data = {
        name: name,
        description: description,
        price: price,
        newarrival: newarrival,
        mostpopular: mostpopular,
        color: color,
        size: size,
        type: "lighter",
      };
      authCtx.updateLighter(id, data, images);
      authCtx.setOpenLighterModal(false);
    }
  };

  return (
    <div>
      <Card className={classes.card1}>
        <div className={classes.inputs_location_container}>
          <div className={classes.info_rows}>
            <TextField
              id="name"
              label="Name*"
              type="search"
              variant="standard"
              value={name}
              className={classes.input_description}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              id="price"
              label="Price*"
              type="search"
              variant="standard"
              value={price}
              className={classes.input_description}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
            <TextField
              id="description"
              label="Description *"
              type="search"
              variant="standard"
              multiline
              rows={3}
              value={description}
              className={classes.input_description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            {/* <TextField
              id="size"
              label="Size*"
              type="search"
              variant="standard"
              value={size}
              className={classes.input_description}
              onChange={(e) => {
                setSize(e.target.value);
              }}
            />
            <TextField
              id="color"
              label="Color*"
              type="search"
              variant="standard"
              value={color}
              className={classes.input_description}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            /> */}
<FormGroup className={classes.checkboxes}>
  <FormControlLabel
    control={<Checkbox checked={newarrival} onChange={(e) => setNewarrival(e.target.checked)} />}
    label="New Arrival"
  />
  <FormControlLabel
    control={<Checkbox checked={mostpopular} onChange={(e) => setMostpopular(e.target.checked)} />}
    label="Most popular"
  />
</FormGroup>

          </div>
          <div className={classes.imagee_container}>
          {viewImages.map((image, index) => (
              <div key={index}>
                <input
                  type="file"
                  ref={fileInputRefs[index]}
                  style={{ display: "none" }}
                  onChange={(event) => handleImageChange(index, event)}
                />
                {!image && (
                  <img
                    src={addImage}
                    alt=""
                    style={{width:'100%', height:'7em', objectFit:'fill'}}
                    onClick={() => fileInputRefs[index].current.click()}
                  />
                )}
                {image && (
                  <img
                    src={viewImages[index]}
                    alt=""
                    style={{width:'100%', height:'7em', objectFit:'fill'}}
                    onClick={() => fileInputRefs[index].current.click()}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>

      <div className={classes.btns}>
        <button className={classes.btncancel} onClick={props.onClose}>
          Cancel
        </button>
        <button className={classes.btnsave} onClick={addHookahBtn}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddLighter;
