import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  addDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import { ref, getDownloadURL, uploadBytes, getStorage } from "firebase/storage"
import { firebaseConfig } from './config';


const AuthContext = React.createContext({
  selectedItem:{},
  allCategories: [],
  setselectedItem: () => { },
  isLoggedIn: false,
  openHomeModal: false,
  openPipeModal: false,
  openAshtrayModal: false,
  openLighterModal: false,
  openLHumidifierModal: false,
  openCigarModal: false,
  openAccessoriesModal: false,
  isLoaded: false,
  setisLoaded:(isLoaded) => { },
  onLogin: () => { },
  onLogout: () => { },
  setOpenHomeModal: (openHomeModal) => { },
  setHome: () => {}, 
  setOpenPipeModal: (openPipeModal) => { },
  setallCategories: () => {},
  setPipe: () => {},
  setOpenAshtrayModal: (openAshtrayModal) => { },
  setAshtray: () => {},
  setOpenLighterModal: (openLighterModal) => { },
  setLighter: () => {},
  setOpenHumidifierModal: (openHumidifierModal) => { },
  setHumidifier: () => {},
  setOpenCigarModal: (openCigarModal) => { },
  setCigar: () => {},
  setOpenAccessoriesModal: (openAccessoriesModal) => { },
  setAccessories: () => {},
});

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// Initialize Firebase

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [editmood, seteditmood] = useState(false);
  const [openHomeModal, setOpenHomeModal] = useState(false);
  const [openPipeModal, setOpenPipeModal] = useState(false);
  const [openAshtrayModal, setOpenAshtrayModal] = useState(false);
  const [openLighterModal, setOpenLighterModal] = useState(false);
  const [openCigarModal, setOpenCigarModal] = useState(false);
  const [openAccessoriesModal, setOpenAccessoriesModal] = useState(false);
  const [openHumidifierModal, setOpenHumidifierModal] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const auth = getAuth();

  const [user, setUser] = useState(null);
  const [allCategories, setallCategories] = useState([]);
  const [home, setHome] = useState([]);
  const [pipe, setPipe] = useState([]);
  const [humidifier, setHumidifier] = useState([]);
  const [cigar, setCigar] = useState([]);
  const [lighter, setLighter] = useState([]);
  const [ashtray, setAshtray] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);


  const signInWithEmailAndPasswordHandler = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, [auth]);


  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  }


  const sortArray = (array) => {
    array.sort((a, b) => a.key - b.key);
  }

//Hookah table

  const addHookah = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadImage(file)));
      const docRef = await addDoc(collection(db, "hookah"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setHome(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "hookah");
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };
  
  const uploadImage = async (file) => {
    try {
      const storageRef = ref(storage, `hookah/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getHookah = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "hookah"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setHome(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("Home data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching home data:", error); // Log any errors
    }
  };
  
  const getImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "hookah", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateHookah = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `hookah/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "hookah", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "hookah", id), { ...data });
      }
  
      // Update the state with the updated document
      setHome(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getHookah()
    } catch (error) {
      console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };



  const deleteHookah = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "hookah", id)).then(() => {
      console.log("deleted");
      setHome(home.filter((other) => other.id !== id));
    });
  }

  //Pipe table

  const addPipe = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadPipeImage(file)));
      const docRef = await addDoc(collection(db, "pipe"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setPipe(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "pipe");
    } catch (error) {
      console.error("Error adding pipe:", error); // Log any errors
    }
  };
  
  const uploadPipeImage = async (file) => {
    try {
      const storageRef = ref(storage, `pipe/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getPipe = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "pipe"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getPipeImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setPipe(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("Home data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching home data:", error); // Log any errors
    }
  };
  
  const getPipeImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "pipe", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updatePipe = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `pipe/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "pipe", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "pipe", id), { ...data });
      }
  
      // Update the state with the updated document
      setPipe(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getPipe()
    } catch (error) {
      console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deletePipe = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "pipe", id)).then(() => {
      console.log("deleted");
      setPipe(pipe.filter((other) => other.id !== id));
    });
  }
  

  //Ashtray Table
  
  const addAshtray = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadImageAshtray(file)));
      const docRef = await addDoc(collection(db, "ashtray"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setAshtray(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "ashtray");
    } catch (error) {
      console.error("Error adding ashtray:", error); // Log any errors
    }
  };
  
  const uploadImageAshtray = async (file) => {
    try {
      const storageRef = ref(storage, `ashtray/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getAshtray = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "ashtray"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getAshtrayImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setAshtray(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("ashtray data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching ashtray data:", error); // Log any errors
    }
  };
  
  const getAshtrayImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "ashtray", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateAshtray = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `ashtray/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "ashtray", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "ashtray", id), { ...data });
      }
  
      // Update the state with the updated document
      setAshtray(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getAshtray()
    } catch (error) {
      console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteAshtray = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "ashtray", id)).then(() => {
      console.log("deleted");
      setAshtray(ashtray.filter((other) => other.id !== id));
    });
  }
  
  
  //Lighter table

  const addLighter = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadLighterImage(file)));
      const docRef = await addDoc(collection(db, "lighter"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setLighter(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "lighter");
    } catch (error) {
      console.error("Error adding lighter:", error); // Log any errors
    }
  };
  
  const uploadLighterImage = async (file) => {
    try {
      const storageRef = ref(storage, `lighter/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getLighter = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "lighter"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getLighterImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setLighter(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("lighter data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching home data:", error); // Log any errors
    }
  };
  
  const getLighterImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "lighter", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateLighter = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `lighter/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "lighter", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "lighter", id), { ...data });
      }
  
      // Update the state with the updated document
      setLighter(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getLighter()
    } catch (error) {
      console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteLighter = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "lighter", id)).then(() => {
      console.log("deleted");
      setLighter(lighter.filter((other) => other.id !== id));
    });
  }
  
  
  //Humidifier table

  const addHumidifier = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadHumidifierImage(file)));
      const docRef = await addDoc(collection(db, "humidifier"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setHumidifier(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "humidifier");
    } catch (error) {
      console.error("Error adding humidifier:", error); // Log any errors
    }
  };
  
  const uploadHumidifierImage = async (file) => {
    try {
      const storageRef = ref(storage, `humidifier/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getHumidifier = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "humidifier"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getHumidifierImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setHumidifier(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("humidifier data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching humidifier data:", error); // Log any errors
    }
  };
  
  const getHumidifierImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "humidifier", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateHumidifier = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `humidifier/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "humidifier", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "humidifier", id), { ...data });
      }
  
      // Update the state with the updated document
      setHumidifier(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getHumidifier()
    } catch (error) {
      console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteHumidifier = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "humidifier", id)).then(() => {
      console.log("deleted");
      setHumidifier(humidifier.filter((other) => other.id !== id));
    });
  }

  
  //Cigar table

  const addCigar = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadCigarImage(file)));
      const docRef = await addDoc(collection(db, "cigar"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setCigar(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "cigar");
    } catch (error) {
      console.error("Error adding cigar:", error); // Log any errors
    }
  };
  
  const uploadCigarImage = async (file) => {
    try {
      const storageRef = ref(storage, `cigar/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getCigar = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "cigar"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getCigarImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setCigar(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("Home data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching home data:", error); // Log any errors
    }
  };
  
  const getCigarImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "cigar", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateCigar = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `cigar/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "cigar", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "cigar", id), { ...data });
      }
  
      // Update the state with the updated document
      setCigar(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getCigar()
    } catch (error) {
      console.error("Error updating cigar:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteCigar = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "cigar", id)).then(() => {
      console.log("deleted");
      setCigar(cigar.filter((other) => other.id !== id));
    });
  }
  
  
  //Accessories table

  const addAccessories = async (data, files) => {
    try {
      const images = await Promise.all(files.map(file => uploadAccessoriesImage(file)));
      const docRef = await addDoc(collection(db, "accessories"), {
        ...data,
        images: images,
      });
      // Update the state with the newly added document
      setAccessories(prev => [...prev, { ...data, images: images, id: docRef.id }]);
      console.log(docRef.id, "accessories");
    } catch (error) {
      console.error("Error adding accessories:", error); // Log any errors
    }
  };
  
  const uploadAccessoriesImage = async (file) => {
    try {
      const storageRef = ref(storage, `accessories/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error); // Log any errors
      return null;
    }
  };

  
  const getAccessories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "accessories"));
      const homeData = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const imageData = await getAccessoriesImagesForDocument(doc.id);
        homeData.push({ ...doc.data(), id: doc.id, images: imageData });
      }));
      setAccessories(homeData);
      setallCategories(prev => prev.concat(homeData))
      console.log("accessories data:", homeData); // Log retrieved data
    } catch (error) {
      console.error("Error fetching accessories data:", error); // Log any errors
    }
  };
  
  const getAccessoriesImagesForDocument = async (docId) => {
    try {
      const docRef = doc(db, "accessories", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const imageData = docSnapshot.data().images;
        return imageData ? imageData : [];
      } else {
        console.error("Document does not exist");
        return [];
      }
    } catch (error) {
      console.error("Error fetching images for document:", error);
      return [];
    }
  };

  
  const updateAccessories = async (id, data, files) => {
    try {
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadTasks = files.map(async (fileOrString, index) => {
          if (fileOrString instanceof File) {
            const storageRef = ref(storage, `accessories/${fileOrString.name}`);
            const snapshot = await uploadBytes(storageRef, fileOrString);
            const url = await getDownloadURL(snapshot.ref);
            return url;
          } else if (typeof fileOrString === 'string') {
            // If it's a string, just return it
            return fileOrString;
          } else {
            // For other cases, return null
            return null;
          }
        });
  
        const urls = await Promise.all(uploadTasks);
  
        // Assuming you want to update the document with an array of URLs
        await updateDoc(doc(db, "accessories", id), { ...data, images: urls });
      } else {
        await updateDoc(doc(db, "accessories", id), { ...data });
      }
  
      // Update the state with the updated document
      setAccessories(prev =>
        prev.map(item => (item.id === id ? { ...data, id } : item))
      );
      getAccessories()
    } catch (error) {
      console.error("Error updating hoaccessoriesme:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteAccessories = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "accessories", id)).then(() => {
      console.log("deleted");
      setAccessories(accessories.filter((other) => other.id !== id));
    });
  }
  

  // Theses functions are redundant for readablity
  // If possible, Refactor them to one function with the collection name as a parameter
  // if you need help, ask Ali

  
  
  
  
  
  




  useEffect(() => {
    getHookah();
    getPipe();
    getAshtray();
    getLighter();
    getHumidifier();
    getCigar();
    getAccessories();
  }, []);





  // useEffect(() => {
  //   const storedUserLoggedInInformation = localStorage.getItem('isLoggedIn');

  //   if (storedUserLoggedInInformation === '1') {
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  
  
  const openmodalHomehandler = (openHomeModal) => {
    setOpenHomeModal(openHomeModal)
  }
  
  const openmodalPipehandler = (openPipeModal) => {
    setOpenPipeModal(openPipeModal)
  }

  const openmodalAshtrayhandler = (openAshtrayModal) => {
    setOpenAshtrayModal(openAshtrayModal)
  }
  
  const openmodalLighterhandler = (openLighterModal) => {
    setOpenLighterModal(openLighterModal)
  }
  
  const openmodalHumidifierhandler = (openHumidifierModal) => {
    setOpenHumidifierModal(openHumidifierModal)
  }
  
  const openmodalCigarhandler = (openCigarModal) => {
    setOpenCigarModal(openCigarModal)
  }
  
  const openmodalAccessorieshandler = (openAccessoriesModal) => {
    setOpenAccessoriesModal(openAccessoriesModal)
  }

  const setselectedItemhandler = (selectedItem) => {
    setselectedItem(selectedItem)
  }

  const contextValue = {
    user,
    signInWithEmailAndPasswordHandler,
    seteditmood,
    setOpenHomeModal,
    setOpenPipeModal,
    setOpenAshtrayModal,
    setOpenLighterModal,
    setOpenCigarModal,
    setOpenHumidifierModal,
    setOpenAccessoriesModal,
    signOut,
    selectedItem: selectedItem,
    setselectedItem: setselectedItemhandler,
    openmodalHomehandler,
    openmodalPipehandler,
    openmodalAshtrayhandler,
    openmodalLighterhandler,
    openmodalCigarhandler,
    openmodalHumidifierhandler,
    openmodalAccessorieshandler,
    addHookah,
    addPipe,
    addAshtray,
    addLighter,
    addCigar,
    addHumidifier,
    addAccessories,
    deleteHookah,
    deletePipe,
    deleteAshtray,
    deleteLighter,
    deleteCigar,
    deleteAccessories,
    deleteHumidifier,
    updateHookah,
    updatePipe,
    updateAshtray,
    updateLighter,
    updateHumidifier,
    updateCigar,
    updateAccessories,
    isLoaded,
    setisLoaded,
    allCategories,
    home,
    pipe,
    ashtray,
    lighter,
    humidifier,
    cigar,
    accessories,
    editmood,
    openHomeModal,
    openPipeModal,
    openAshtrayModal,
    openLighterModal,
    openCigarModal,
    openHumidifierModal,
    openAccessoriesModal,
    setHome,
    setPipe,
    setallCategories,
    setAshtray,
    setLighter,
    setCigar,
    setHumidifier,
    setAccessories,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

