import React, { useEffect, useState } from 'react'
import classes from "./ProductDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addTocart,
} from "../../../Common/redux/productSlice";



import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";


const ProductDetails = () => {
    const dispatch = useDispatch();
    const [quantity, setquantity] = useState(1);

    const selectedProduct = useSelector((state) => state.products.selectedBook);

      const [activeSlideIndex, setActiveSlideIndex] = useState(-1);
      const [activeId, setactiveId] = useState(0);
      
  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex-1);
  };

  const constantValue = activeSlideIndex + 1;
  useEffect(() => {
    selectedProduct[0].images.forEach((item, index) => {
      if (index === constantValue) {
        setactiveId(index); 
      }
    });
  }, [constantValue,selectedProduct]);


  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
if(swiper) 
swiper.slideTo(index)};
      
  const handleMouseMove = (e) => {
    const image = e.target;
    const boundingRect = image.getBoundingClientRect();
    
    const x = (e.clientX - boundingRect.left) / boundingRect.width;
    const y = (e.clientY - boundingRect.top) / boundingRect.height;

    image.style.transformOrigin = `${x * 100}% ${y * 100}%`;
  };
    
  return (
    <>
      <div className={classes.detailsContainer}>
        <div className={classes.bigContainer}>
            <div className={classes.swiper}>
              <div style={{display:"flex",flexDirection:"row",width:'100%'}}>
              <Swiper
              onSlideChange={handleSlideChange}
              onSwiper={setSwiper}
                slidesPerView={1}
              >
                {selectedProduct[0].images?.map((props) => {
                    if (props) {
                      return (
                        <SwiperSlide key={props}>
                          <div className={classes.imageContainer}>
                            <img src={props} alt="Book Cover" onMouseMove={handleMouseMove} />
                          </div>
                        </SwiperSlide>
                      );
                    } else {
                      return null; // Return nothing when props is null
                    }
                  })}
              </Swiper>
             </div> 
          <div className={classes.bookCoversContainerMob}>
            {selectedProduct[0].images?.map((props, index) => {
              if (props) {
              return (
                <div className={classes.bookCovers} onClick={() => slideTo(index)}>
                  <img src={`${props}`} className={`${activeId === index ? classes.bookCoverSelectedimg : classes.bookCoverimg }`} alt="Book Cover"/>
                </div>
              )
            } else {
              return null; // Return nothing when props is null
            }})} 
            </div>
          </div>
        <div className={classes.details}>
          <div style={{width:'90%',margin:'auto',height:'90%',justifyContent:'space-between',display:'flex',flexDirection:'column'}}>
            <h1>{selectedProduct[0].name}</h1>
            <h2>$ {selectedProduct[0].price}</h2>
            <p>{selectedProduct[0].description}</p>
            <div style={{width:'100%',display:'flex',flexWrap:'wrap',gap:'2em',marginBottom:'.5em'}}>
            <h3>Quantity</h3>
          <div className={classes.quantity}>
            <p style={{
                color: "#000",
                fontWeight: 500,
                margin: "auto",
                cursor: "pointer",
                fontSize: 'calc(1.7rem + 0.3vw)',
              }} onClick={() =>{if (quantity !== 1){setquantity(quantity - 1);}}}> - </p>
            <p style={{
                color: "#000",
                fontWeight: 600,
                margin: ".7em auto",
              }} >{quantity} </p>
            <p style={{
                color: "#000",
                fontWeight: 500,
                margin: "auto",
                cursor: "pointer",
                fontSize: 'calc(1.7rem + 0.3vw)',
              }} onClick={() =>{setquantity(quantity + 1)}}>+</p>
          </div>

            </div>
            <button className={classes.addBtn} onClick={()=>dispatch(addTocart({...selectedProduct[0], quantity: quantity}))}>Add To Bag</button>
          </div>
        </div>
        </div>
          <div className={classes.bookCoversContainer}>
          {selectedProduct[0].images?.map((props, index) => {
              if (props) {
              return (
                <div className={classes.bookCovers} onClick={() => slideTo(index)}>
                  <img src={`${props}`} className={`${activeId === index ? classes.bookCoverSelectedimg : classes.bookCoverimg }`} alt="Book Cover"/>
                </div>
              )
            } else {
              return null; // Return nothing when props is null
            }})} 
            </div>
      </div>
    </>
  );
};

export default ProductDetails;
