import React, { useEffect, useState } from 'react'
import classes from './Instagram.module.css'
import axios from 'axios';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";


const Instagram = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const accessToken = 'IGQWRQNjBuZAnJ0ZAUtkcU5LOWhBN0tQQjJPT0hpSjBlYlAyRG96UjBRdm1zamY0MmZADS3dKN1hXcXZAndVZAoWmpBcWhjOFA1NVBvZAjdzeHBHbDRfS0UzUDRYOWlWYzlrNm5fVWQyRnpUUEFZATXRwcWtZAOF9RUGd1d3MZD';
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${accessToken}`
        );
        const imagePosts = response.data.data.filter(post => post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM');
        setPosts(imagePosts);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchPosts();
  }, []);
  return (
    <div className={classes.bigContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h1 style={{margin:'.2em 0'}}>Follow Us On Instagram</h1>
            <div className={classes.border} />
            <p style={{cursor:'pointer'}} onClick={()=>window.open('https://www.instagram.com/luxury_smoke.lb/', '_blank')}>@luxurysmoke.lb</p>
          </div>
        </div>
        <div className={classes.content}>
          
      <Splide
      options={{
        type: "loop",
        // gap: "50px",
        drag: false,
        arrows: false,
        pagination: false,
        width: '100%',
        perPage: 6,
        autoScroll: {
          pauseOnHover: false,
          pauseOnFocus: true,
          rewind: false,
          speed: 1
        },
        autoWidth:true,
        omitEnd  : true,
      }}
      extensions={{ AutoScroll }}
    >
        {posts.map((props, index) => {
          if (props.media_url) {
          return (
              <SplideSlide key={index} className={classes.slide} >
                <div className={classes.image_con} style={{cursor:'pointer'}} onClick={()=>console.log(props)}>
                                  <img src={props.media_url} alt="Image" className={classes.movearound_img} style={{margin: index % 2 === 0 ? '2.5vw 0' : '0', height: index % 2 === 0 ? '20vw' : '25vw', maxHeight:index % 2 === 0 ? '20em' : '25em' }}/>
                </div>
              </SplideSlide>
          );
          } else {return null;}
        })}
        </Splide>
        </div>
    </div>
  )
}

export default Instagram