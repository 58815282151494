import React, { useContext, useEffect, useState } from "react";
import classes from "./ProductList.module.css";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import {  useNavigate, useParams } from "react-router";
import AuthContext from "../../../Common/auth-context";
import { useDispatch } from "react-redux";
import { addSelectedBook, addTocart } from "../../../Common/redux/productSlice";


const ProductList = () => {
    const { Collection } = useParams();
  const [dataa, setdataa] = useState([]);
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Collection === 'Hookah') {
      setdataa(authCtx.home)
    } else {
      if (Collection === 'Pipe') {
        setdataa(authCtx.pipe)
      } else {
        if (Collection === 'Ashtray') {
          setdataa(authCtx.ashtray)
        } else {
          if (Collection === 'Lighter') {
            setdataa(authCtx.lighter)
          } else {
            if (Collection === 'Humidifier') {
              setdataa(authCtx.humidifier)
            } else {
              if (Collection === 'Cigar') {
                setdataa(authCtx.cigar)
              } else {
                if (Collection === 'Accessories') {
                  setdataa(authCtx.accessories)
                } else {
                  if (Collection === 'All_Products') {
                    setdataa(authCtx.allCategories)
                  }
                }  
              }  
            } 
          }
        }
      }
    }
  }, [Collection]);
  return (
    <>
      <div className={classes.bigContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h1 style={{margin:'.2em 0'}}>{Collection === 'All_Products' ? 'All Products' : Collection} </h1>
            <div className={classes.border} />
          </div>
        </div>
        <div className={classes.cardsContainer}>
            {dataa?.map((props) => {
              return (
                  <div className={classes.card_container} onClick={(event)=>{event.stopPropagation();dispatch(addSelectedBook({...props, quantity: 1}));navigate(`/products-details/${props.id}`)}}>
                    <div className={classes.card_img}>
                      <img
                          src={props.images[0]}
                          alt=""
                          className={classes.img}
                        />
                      <div className={classes.addContainer} onClick={(event)=>event.stopPropagation()&dispatch(addTocart({...props, quantity: 1}))}>
                      <LocalMallOutlinedIcon style={{marginBottom:'-.2em'}}/> Add To Bag
                      </div>
                    </div>
                    <div className={classes.card_content}>
                      <h3 style={{width:'100%',fontWeight:'800',fontSize:'calc(0.8rem + 0.3vw)'}}>{props.name}</h3>
                      <p style={{width:'100%',fontWeight:'700',margin:'0'}}>$ {props.price}</p>
                    </div>
                  </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ProductList;
