import * as React from "react";
import Modal from "@mui/material/Modal";

import classes from "./AccessoriesModal.module.css";
import AuthContext from "../../../../Common/auth-context";
import AddAccessories from "./AddAccessories";

const AccessoriesModal = (props) => {
  const { lable, handleClose } = props;
  const authCtx = React.useContext(AuthContext);
  // console.log(client_name);
  return (
    <div>
      <Modal
        open={authCtx.openAccessoriesModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.maincontainer}>
          <div className={classes.mainheader}>
            <p className={classes.mainhead}>Accessories</p>
            <p>{lable}</p>
          </div>
          <div className={classes.storecontainer}>
            <AddAccessories onClose={props.handleClose}/>
          </div>


        </div>
      </Modal>
    </div>
  );
};

export default AccessoriesModal;
