import classes from "./Heru.module.css";
import React, { useEffect, useRef, useState } from "react";
import heroImg from '../../../../assets/Home.png'


const HeruSection = () => {

  return (
    <div className={classes.heru}>
      <div className={classes.content}>
        <div className={classes.text} >
          <h1>Experience Luxury Hookah & Tobacco</h1>
          <p>
          Shop the finest selection of tobacco, hookahs, and smoking accessories
          </p>
          <button>Shop Now</button>
        </div>
        <div className={classes.imageCont}>
          <div className={classes.card_img}>
            <img src={heroImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeruSection;
