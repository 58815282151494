import React, { useEffect } from "react";
import classes from "./Footer.module.css";
import Logoimg from "../../../assets/newww.svg";
import { Link } from "react-scroll";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
// import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


function Footer() {

  return (
    <>
      <footer className={classes.footer_desktop_con}>
           <div className={classes.footer_logo}>
            <Link
          to="home"
          smooth={true}
          duration={500}
        >
            <img src={Logoimg} alt="Everest-logo" className={classes.logoo}/>
            <div style={{display:'flex',flexDirection:'row',margin:'2em auto',width:'fit-content',gap:'1em'}}>
              <div className={classes.iconContainer} onClick={()=>window.open('https://youtube.com/@luxurysmokelb?si=VVw9hv7izWc41RWK', '_blank')}><FaYoutube style={{fontSize:'1.3em',marginTop:'.3em'}}/></div>
              <div className={classes.iconContainer} onClick={()=>window.open('https://www.instagram.com/luxury_smoke.lb/', '_blank')}><FaInstagram style={{fontSize:'1.3em',marginTop:'.3em'}}/></div>
              <div className={classes.iconContainer} onClick={()=>window.open('https://www.facebook.com/share/ngrTrJQrvcuXL3Kq/?mibextid=LQQJ4d', '_blank')}><FaFacebookF style={{fontSize:'1.3em',marginTop:'.3em'}}/></div>
              <div className={classes.iconContainer} onClick={()=>window.open('https://www.tiktok.com/@luxury_smokeee?_t=8lLork3vYAh&_r=1', '_blank')}><FaTiktok style={{fontSize:'1.3em',marginTop:'.3em'}}/></div>
            </div>
            </Link>
            </div>
            <div className={classes.footer_content} >
              <div className={classes.content}>
                <p className={classes.permalinks__options}>Luxury Smoke © 2024</p>
                <p className={classes.permalinks__options}>All Rights Reserved</p>
              </div>
              <div className={classes.content1} >
                <p className={classes.permalinks__options}>Policies</p>
                <p className={classes.permalinks__options}>Shop</p>
                <p className={classes.permalinks__options}>Contact Us</p>
              </div>
            </div>
          
      </footer>
    </>
  );
}

export default Footer;
