import classes from "./Heru.module.css";
import React, { useEffect, useState } from "react";
import HomeCegar from '../../../../assets/Home1.png'
import HomeHookah from '../../../../assets/HomeHookah.png'
import HomePipe from '../../../../assets/HomePipe.png'
import HomeHumidifier from '../../../../assets/HomeHumidifier.png'
import HomeAccessories from '../../../../assets/HomeAccessories.png'
import HomeAshtray from '../../../../assets/HomeAshtray.png'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";


const HeruSection = () => {
  const Collection  = useSelector((state) => state.products.selectedBook);
  const [dataa, setdataa] = useState(HomeCegar);

  useEffect(() => {
    if (Collection[0].type === 'hookah') {
      setdataa(HomeHookah)
    } else {
      if (Collection[0].type === 'pipe') {
        setdataa(HomePipe)
      } else {
        if (Collection[0].type === 'ashtray') {
          setdataa(HomeAshtray)
        } else {
          if (Collection[0].type === 'lighter') {
            setdataa(HomePipe)
          } else {
            if (Collection[0].type === 'humidifier') {
              setdataa(HomeHumidifier)
            } else {
              if (Collection[0].type === 'cigar') {
                setdataa(HomeCegar)
              } else {
                if (Collection[0].type === 'accessories') {
                  setdataa(HomeAccessories)
                } else {
                    setdataa(HomeCegar)
                }  
              }  
            } 
          }
        }
      }
    }
  }, [Collection]);
  return (
    <div className={classes.heru}>
        <div className={classes.imageCont} onClick={()=>console.log(Collection)}>
          <img src={dataa} alt="" />
      </div>
    </div>
  );
};

export default HeruSection;
