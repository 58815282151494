import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./CheckOutItem.module.css";
import DeleteIcon from "../../../assets/DeleteIcon.svg";
import { deleteItem } from "../../Common/redux/productSlice";
import { Rate, Input } from "antd";

const CheckOutItem = () => {
      const dispatch = useDispatch();
    const productData = useSelector((state) => state.products.cartItems);
    return (
      <>
        {productData?.map((props) => (
          <div className={classes.card} key={props._id}>
            <div style={{display:"flex",flexDirection:"row",gap:"1em"}}>
            <div className={classes.imageCont}>
              <img src={props.images[0]} alt="" style={{height:'100%', width: '100%',objectFit:'cover' ,maxWidth:'350px' }}/>
            </div>
            <div style={{height:'100%',justifyContent:'space-between',display:'flex', flexDirection:'column', margin:'auto 0',width:'fitcontent',fontSize:'calc(.7rem + 0.3vw)',fontFamily:'Raleway'}}>
              <div className={classes.infoCont}>
              <p style={{color:'#000',fontSize:'calc(.9rem + 0.3vw)',fontWeight:'700',width:"97%"}}>{props.quantity} X {props.name}</p>
              </div>
              <p className={classes.dicription}> {props.description.slice(0,90)}</p>
              <p style={{fontWeight:'600',color:'#000'}}>${(props.quantity * props.price).toFixed(2)}</p>
            </div>
            </div>
            <div className={classes.quantity}>
            <div className={classes.delete_btn}><img src={DeleteIcon} style={{width:'1em'}} onClick={() =>
                      dispatch(deleteItem(props.id)) 
                    } /></div>
            <p style={{margin:'0 0 0 auto',fontWeight:'600',color:'#000'}}>${(props.quantity * props.price).toFixed(2)}</p>
            </div>
            <div className={classes.delete_btnMob}><img src={DeleteIcon} style={{width:'1em'}} onClick={() =>
                      dispatch(deleteItem(props.id)) 
                    } /></div>
          </div>
        ))}
      </>
    );
  };

export default CheckOutItem