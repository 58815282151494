import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  selectedBook: [],
};

export const productSlice = createSlice({
  name: "LuxurySmokes",
  initialState,
  reducers: {
    
    addTocart: (state, action) => {
        const item = state.cartItems.find(
          (item) => item.id === action.payload.id
        );
        if (item) {
          console.log('presentt')
          item.quantity += action.payload.quantity;
        } else {
          console.log('newwww')
          state.cartItems.push(action.payload);
        }
    },
    deleteItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload
      );
    },
    resetCart: (state) => {
      state.cartItems = [];
    },
    increamentQuantity: (state, action) => {
      console.log('action.payload.id')
      const item = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (item) {
        item.quantity++;
      }
    },
    changeQuantity: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
    },
    decreamentQuantity: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (item) {
        item.quantity--;
      }
    },

    addSelectedBook: (state, action) => {
      state.selectedBook = [];
      const book = action.payload;

      state.selectedBook.push(book);
    },

    deleteSelectedBook: (state) => {
      state.selectedBook = [];
    },
  },
});

export const {
  addTocart,
  deleteItem,
  resetCart,
  addSelectedBook,
  deleteSelectedBook,
  increamentQuantity,
  decreamentQuantity
} = productSlice.actions;
export default productSlice.reducer;
