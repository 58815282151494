import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import classes from "./Cartsidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
// import times from "../../../assets/cartimgs/times.svg";
import {
  decreamentQuantity,
  deleteItem,
  increamentQuantity, 
} from "../../Common/redux/productSlice";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../assets/DeleteIcon.svg";
import { FaRegHeart } from "react-icons/fa";

export default function CartSidebar({ toggle, isOpen }) {
  const cartItems = useSelector((state) => state.products.cartItems);
  const [totalAmt, setTotalAmt] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    let price = 0;
    cartItems.map((item) => {
      price += item?.quantity * item.price;
      return price;
    });
    setTotalAmt(price.toFixed(2));
  }, [cartItems]);
  const [state, setState] = React.useState({
    right: false,
  });

  const list = (anchor) => (
    <>
      <Box
        role="presentation"
        //   onClick={toggle}
        onKeyDown={toggle}
        className={classes.cartsidebar} style={{background:'#fff'}}
      >
        <List >
          <ListItem disablePadding>
            {/* <ListItemButton> */}
            <ListItemText
              style={{
                textAlign: "start",
                padding:'0 3%'
              }}
            >
               <div className={classes.header}>
                  <div className={classes.title}>
                    <h1 style={{margin:'.2em 0'}}>Shopping Bag</h1>
                    <div className={classes.border} />
                  </div>
                  <button className={classes.closeBtn} onClick={toggle}><ClearIcon/></button>
                </div>
            </ListItemText>
          </ListItem>
        </List>
        <>
        {cartItems.map((props) => (
          <div className={classes.card} key={props.id}>
            <div className={classes.imageCont}>
              <img src={props.images[0]} alt="" style={{height:'100%', width: '100%',objectFit:'cover' ,maxWidth:'350px' }}/>
            </div>
            <div style={{height:'100%',justifyContent:'space-between',display:'flex', flexDirection:'column', margin:'auto 0',width:'100%',maxWidth:'20em',fontSize:'calc(.7rem + 0.2vw)',fontFamily:'Raleway'}}>
              <p style={{color:'#000',fontSize:'calc(.9rem + 0.2rem)',fontWeight:'700'}}>{props.name}</p>
              <p className={classes.dicription}> {props.description.slice(0,90)}... </p>
              <div style={{display:'flex',flexDirection:'row',gap:'1em'}}>
                  <p className={classes.price}>$ {props.price}</p>
                  <div className={classes.quantity}>
                      <p style={{
                          color: "#000",
                          fontWeight: 500,
                          margin: "auto",
                          cursor: "pointer",
                          fontSize: 'calc(1.7rem + 0.3vw)',
                        }} onClick={() =>{if (props.quantity != 1){dispatch(decreamentQuantity(props));}
                        else{deleteItem(props.id)}}}> - </p>
                      <p style={{
                          color: "#000",
                          fontWeight: 600,
                          margin: ".5em auto",
                        }} >{props.quantity} </p>
                      <p style={{
                          color: "#000",
                          fontWeight: 500,
                          margin: "auto",
                          cursor: "pointer",
                          fontSize: 'calc(1.7rem + 0.3vw)',
                        }} onClick={() =>{dispatch(increamentQuantity(props))}}>+</p>
                    </div>
              </div>
            </div>
            <div className={classes.deleteCon}>
              <div style={{display:'flex',flexDirection:'column', gap:'1em'}}>
                <div className={classes.delete_btn}>
                  <img src={DeleteIcon} style={{width:'1em'}} onClick={() =>
                      dispatch(deleteItem(props.id))
                    } /></div> 
              </div>
            
            
            <p style={{margin:'0 0 .5em auto',color:'var(--forth-color)',fontSize:'calc(.7rem + 0.3vw)',fontWeight:'600'}}>${(props.quantity * props.price).toFixed(2)}</p>
            </div>
          </div>
        ))}
      </>
        <List>
          <ListItem disablePadding>
            {/* <ListItemButton> */}
            <ListItemText
              style={{
                textAlign: "start",
              }}
            >
              <div style={{width:'90%',margin:'0 auto',fontWeight:'600',display:'flex',flexDirection:'row',justifyContent:"space-between",fontFamily:'Raleway',fontSize:'calc(1rem + 0.3vw)'}}>
                <p style={{color:'#000' }}>Subtotal</p>
                <p style={{color:'var(--forth-color)'}}>$ {totalAmt}</p>
              </div>
                <div className={classes.btn_con}>
              <button className={classes.btn} onClick={()=>navigate(`/check-out`) & toggle()}>
              Checkout
              </button></div>
            </ListItemText>
            {/* </ListItemButton> */}
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={isOpen} onClose={toggle}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
