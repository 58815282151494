import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import logo from "../../../assets/newww.svg";
import LogoDesktop from "../../../assets/LogoDesktop.svg";
import SearchBox from "./Search box/SearchBox";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useSelector } from "react-redux";


const Navbar = (props) => {
  const cartItems = useSelector((state) => state.products.cartItems);

  return (
    <div className={classes.navbar}>
      {props.isOpenHeader && (
        <div className={classes.header}>
          <p>
          Experience the Difference: Discover the World of Premium Tobacco & Exquisite Smokes          </p>
          <CloseOutlinedIcon
            className={classes.header_close}
            onClick={props.removeheader}
          />
        </div>
      )}
      <div className={classes.nav_head}>
        <div className={classes.search }>
          <SearchBox />
        </div>
        <div className={classes.logo}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={classes.LogoDesktop}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={LogoDesktop} alt="logo" />
          </Link>
        </div>
        <div className={classes.web_control}>
          <div
            className={classes.user}
            onClick={() => props.carttoggle()}
          >
              <LocalMallOutlinedIcon
                className={classes.cart_icon}
              />
            <p>
            Bag ({cartItems?.length})
            </p>
          </div>
        </div>
      </div>
      <div className={classes.nav}>
        <div className={classes.navs_link}>
          <Link to="/products-page/All_Products" style={{ textDecoration: "none" }}>
            <p>Shop All</p>
          </Link>
          <Link to="/products-page/Hookah" style={{ textDecoration: "none" }}>
            <p>Hookah</p>
          </Link>
          <Link to="/products-page/Pipe" style={{ textDecoration: "none" }}>
            <p>Pipe</p>
          </Link>
          <Link to="/products-page/Ashtray" style={{ textDecoration: "none" }}>
            <p>Ashtray</p>
          </Link>
          <Link to="/products-page/Lighter" style={{ textDecoration: "none" }}>
            <p>Lighter</p>
          </Link>
          <Link to="/products-page/Humidifier" style={{ textDecoration: "none" }}>
            <p>Humidifier</p>
          </Link>
          <Link to="/products-page/Cigar" style={{ textDecoration: "none" }}>
            <p>Cigar</p>
          </Link>
          <Link to="/products-page/Accessories" style={{ textDecoration: "none" }}>
            <p>Accessories</p>
          </Link>
        </div>
      </div>
      <div className={classes.mobile_nav}>
        <MenuRoundedIcon onClick={props.toggle} className={classes.mob_icon} />
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={logo} alt="logo" />
        </Link>
        <div
            className={classes.user}
            onClick={() => props.carttoggle()}
          >
              <LocalMallOutlinedIcon
                className={classes.cart_icon}
              />
            <p>
            Bag ({cartItems?.length})
            </p>
          </div>
      </div>
    </div>
  );
};

export default Navbar;
