import classes from "./Heru.module.css";
import React, { useEffect, useState } from "react";
import HomeCegar from '../../../../assets/Home1.png'
import HomeHookah from '../../../../assets/HomeHookah.png'
import HomePipe from '../../../../assets/HomePipe.png'
import HomeHumidifier from '../../../../assets/HomeHumidifier.png'
import HomeAccessories from '../../../../assets/HomeAccessories.png'
import HomeAshtray from '../../../../assets/HomeAshtray.png'
import { useParams } from "react-router-dom";


const HeruSection = () => {
  const { Collection } = useParams();
  const [dataa, setdataa] = useState(HomeCegar);

  useEffect(() => {
    if (Collection === 'Hookah') {
      setdataa(HomeHookah)
    } else {
      if (Collection === 'Pipe') {
        setdataa(HomePipe)
      } else {
        if (Collection === 'Ashtray') {
          setdataa(HomeAshtray)
        } else {
          if (Collection === 'Lighter') {
            setdataa(HomePipe)
          } else {
            if (Collection === 'Humidifier') {
              setdataa(HomeHumidifier)
            } else {
              if (Collection === 'Cigar') {
                setdataa(HomeCegar)
              } else {
                if (Collection === 'Accessories') {
                  setdataa(HomeAccessories)
                } else {
                  if (Collection === 'All_Products') {
                    setdataa(HomeCegar)
                  }
                }  
              }  
            } 
          }
        }
      }
    }
  }, [Collection]);
  return (
    <div className={classes.heru}>
        <div className={classes.imageCont}>
          <img src={dataa} alt="" />
      </div>
    </div>
  );
};

export default HeruSection;
