import classes from "./Gravity.module.css";
import React, { useEffect, useRef, useState } from "react";
import GravityHookah from '../../../../assets/GravityHookah.png'
import GravityHookah1 from '../../../../assets/GravityHookah1.png'
import { useNavigate } from "react-router-dom";


const GravitySection = () => {

  const navigate = useNavigate();

  return (
    <div className={classes.gravityContainer}>
      <div className={classes.content}>
          <div className={classes.card_img1}>
            <img src={GravityHookah1} alt="" />
          </div>
          <div className={classes.card_img}>
            <img src={GravityHookah} alt="" />
          </div>
        <div className={classes.text} >
          <div className={classes.border}></div>
          <h1>El Bomber - OFF ROAD 4x4</h1>
          <p>
          The bowl cone and dip tube with diffuser are attached to ideal threaded connections. For compactness – ease of washing. The central part is collapsible.          </p>
          <button onClick={()=>navigate(`/products-page/Hookah`)}>Shop Now</button>
        </div>
      </div>
    </div>
  );
};

export default GravitySection;
