import { useContext, useState } from "react";
import classes from "./AdminPanel.module.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthContext from "../../Common/auth-context";
import LOGO from "../../../assets/newww.svg";
import LogoDesktop from "../../../assets/LogoDesktop.svg";
import LOGO1 from "../../../assets/newww1.svg";
import HookahTable from "./Hookah/Hookah";
import PipeTable from "./Pipe/Pipe";
import AshtrayTable from "./Ashtray/Ashtray";
import LighterTable from "./Lighter/Lighter";
import HumidifierTable from "./Humidifier/Humidifier";
import CigarTable from "./Cigar/Cigar";
import AccessoriesTable from "./Accessories/Accessories";
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import Hookah from '../../../assets/HookahW.png';
import { GiSmokingPipe } from "react-icons/gi";
import { GiCigar } from "react-icons/gi";
import { GiLighter } from "react-icons/gi";
import { GiSmokeBomb } from "react-icons/gi";
import { BsFillBasket3Fill } from "react-icons/bs";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminPanel = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuOption, setMenuOption] = useState("logo");
  const authCtx = useContext(AuthContext);

  const overlay = classes.overlay;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className={classes.cssssss}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={() => setMenuOption("logo")}
            >
              <img src={LOGO1} alt="" width="100" style={{width:'10em'}} />
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader className={classes.sidebartop}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => setMenuOption("logo")}
          >
            <img src={LogoDesktop} alt="" width="100" className={classes.logooo} />
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon className={classes.arrow} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className={classes.sidebartop1}>
          <div
            onClick={() => {
              setMenuOption("hookah");
            }}
            className={menuOption === "hookah" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "hookah" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img alt='Hookah' src={Hookah} style={{width:'1em'}}
                    className={
                      menuOption === "hookah"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Hookah" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            onClick={() => {
              setMenuOption("pipe");
            }}
            className={menuOption === "pipe" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "pipe" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GiSmokingPipe style={{fontSize:'2em'}}
                    className={
                      menuOption === "pipe"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Pipe" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            onClick={() => {
              setMenuOption("ashtray");
            }}
            className={menuOption === "ashtray" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "ashtray" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BsFillBasket3Fill style={{fontSize:"1.2em"}}
                    className={
                      menuOption === "ashtray"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Ashtray"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            onClick={() => {
              setMenuOption("lighters");
            }}
            className={menuOption === "lighters" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "lighters" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GiLighter style={{fontSize:'1.5em'}}
                    className={
                      menuOption === "lighters"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Lighters"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>

          <div
            onClick={() => {
              setMenuOption("humidifier");
            }}
            className={menuOption === "humidifier" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "humidifier" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GiSmokeBomb style={{fontSize:'2em'}}
                    className={
                      menuOption === "humidifier"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Humidifier"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            onClick={() => {
              setMenuOption("cigar");
            }}
            className={menuOption === "cigar" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "cigar" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GiCigar style={{fontSize:'1.7em'}}
                    className={
                      menuOption === "cigar"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Cigar" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
          <div
            onClick={() => {
              setMenuOption("accessories");
            }}
            className={menuOption === "accessories" ? `${classes.overlay}` : ""}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "accessories" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <StarBorderPurple500Icon
                    className={
                      menuOption === "accessories"
                        ? `${classes.iconsSelected}`
                        : `${classes.icons}`
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Accessories" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
        </List>

        <Divider />

        <List className={classes.sidebar}>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={authCtx.signOut}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                // justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Box sx={{ flexGrow: 1 ,overflow:'scroll'}}>
        {menuOption === "logo" && (
          <div className={classes.test}>
            <img src={LOGO} alt="" style={{width:"45vw"}} />
          </div>
        )}
        {menuOption === "hookah" && <HookahTable />}
        {menuOption === "pipe" && <PipeTable />}
        {menuOption === "ashtray" && <AshtrayTable />}
        {menuOption === "lighters" && <LighterTable />}
        {menuOption === "humidifier" && <HumidifierTable />}
        {menuOption === "cigar" && <CigarTable />}
        {menuOption === "accessories" && <AccessoriesTable />}
      </Box>
    </Box>
  );
};
export default AdminPanel;
